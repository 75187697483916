/* custom.css */
body {
}
.lower-carousel-height {
  max-height: 400px; 
  border-radius: '15px',

}
.placecolor{
    color:'#F64460'
}
.link-no-underline {
    text-decoration: none; 
    color: inherit; 
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color:red; 
    border-color: blue; 
  }
  .custom-container {
    height: 100vh;
  }
  .errorField{
    border-color: red!important;
  }
  .calendar-header {
    font-size: 24px;
    font-weight: bold;
  }
  
  .calendar-days {
    font-weight: bold;
  }
  
  .calendar-day {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .calendar-grid {
    display: flex;
    flex-wrap: wrap;
  }
  

  .calendar-day {
    padding: 10px;
    text-align: center;
    min-height: 40px;
    border:none;
  }
  
  .day-label {
    font-weight: bold;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  .arrow-button {
    color: black;
  }
  
  .arrow-button:hover {
    text-decoration: none;
    color: black;
  }
 

  .sunday, .sunday.calendar-day {
    color: #F17C38;
  }
  
  .calendar-day {
    padding: 10px;
    text-align: center;
    min-height: 40px;
    border-radius: 50%;
  }
  
  .day-label {
    font-weight: bold;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .empty {
    visibility: hidden;
  }
  
  .calendar-day {
    position: relative;
  }
  
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .sunday .dot {
    bottom: 2px; 

  }
  .calendar-day {
    position: relative;
  
  }
  
  /* .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
  }
   */
  .dot:nth-child(1) {
    left: 5px;
    background: green;
    padding-left:0px;
  }
  
  .dot:nth-child(2) {
    left: 20px; 
    background: red; 
  }
  

  .dot:nth-child(3) {
    left: 20px; 
    background:#E85C33; 
  }

  .dot:nth-child(3) {
    left: 20px; 
    background:#E85C33; 
  }
  
  
  .selected-day {
    color: #fff;
    /* border:  solid #E85C33;  */
    background:#E85C33;
    /* border-radius:none; */
    border-radius:6px;

  }
  
  
  .vertical-line {
    border-left: 1px solid black; 
    height: 100%; 
  }

  .custom-checkbox .form-check-label {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
.modal button.close {
  border: none;
}


@media (max-width: 576px) {
  .renew-button {
    width: 40%;
    padding-left: 0; 
    padding-right: 0; 
  }
}
.custom-modal .modal-dialog {
  max-width: 150px; /* Adjust the width as needed */
  margin: 1.75rem auto;
}




.calendar-day {
  border: 1px solid transparent; /* Add a border to each calendar day */
  border-radius: 4px; /* Optional: Rounded corners for the border */
  cursor: pointer;
}

.calendar-day.active {
  border-color: #188a67; /* Border color for the active (selected) day */
}

.date-picker-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}


.input-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; 
}
.react-datepicker-wrapper input[type="text"] {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}
.react-datepicker__day {
  font-size: 14px;
  padding: 8px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
}
.react-datepicker__day--selected {
  background-color: #007bff;
  color: #ffffff;

}

.react-datepicker__header {
  background-color: #f0f0f0;
  padding-top: 8px;
  color: #333;
}
.set-height{
  min-height: 50px;
  word-wrap: break-word;
  /* max-height: 55px; */
}
.custom-border {
  border: 2px solid #303A5B; 
  border-radius: 8px;
}

 
.custom-dropdown .dropdown-menu {
  min-width: auto; /* Adjust this value to reduce the width */
}

.custom-date-picker {
  width: calc(100% - 50px); /* Adjust the width as needed */
  height: 34px; /* Adjust the height as needed */
  padding: 6px 12px; /* Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
}

/* Add CSS for the loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}


/* Loader.css */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.btn-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 5vh; */
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #E85C33; /* Change the color as needed */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.btn-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff; /* Change the color as needed */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 10px;
  font-weight: bold;
  color:#E85C33; /* Match the spinner color */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #E85C33;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #c43003;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #E85C33;
  border-color: #E85C33;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.border-thicker{
  border-bottom: 2px solid #333;

}
.order-check{
  accent-color:#E85C33;
 
}
.order-check-form {
  accent-color:#E85C33;
  border-radius: 5px; 
  width: 16px; /* Adjust the width and height of the checkbox */
  height: 16px;
}
.child-pay-pic{
  width: 5.3%;
}

/* @media screen (max-width:700px) {
  
} */

.color-custom {
  background-color: #ccc; /* Change this to your desired color */
}